import * as React from 'react'
import { useLocation } from 'react-router'
import { Location } from 'history'

import { addState, Dispatcher } from '../../lib/add-state'
import { track } from '../../lib/data-layer'

import { GalleryUI } from './ui'

import { ItemType, ReleaseAllImagesQuery } from '../../api/types'

type Images = NonNullable<ReleaseAllImagesQuery['release']>['images']

export type Props = {
    imageId: string
    open: boolean
    loading: boolean
    editUrl?: string
    images?: Images
    error?: Error
    makeUrl: (imageId: string | null, isPerma?: boolean) => Location
    name?: string
    discogsId?: number
    itemType?: ItemType
}

export type State = {
    zoomedIn: boolean
    permalink: boolean
    windowHeight: number
}

export type Action =
    | { type: 'zoomin' }
    | { type: 'zoomout' }
    | { type: 'permalink' }
    | { type: 'resize'; height: number }
    | { type: 'closed' }
    | { type: 'close-permalink' }

export const initial = {
    zoomedIn: false,
    permalink: false,
    windowHeight: 0,
}

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'zoomin':
            track({ event: 'zoomImg', category: 'imageGallery' })
            return { ...state, zoomedIn: true }
        case 'zoomout':
            return { ...state, zoomedIn: false }
        case 'permalink':
            track({ event: 'clickPermalink', category: 'imageGallery' })
            return { ...state, permalink: true }
        case 'close-permalink':
            return { ...state, permalink: false }
        case 'resize':
            return { ...state, windowHeight: action.height }
        case 'closed':
            return { ...state, permalink: false }
        default:
            return state
    }
}

export function useEffect(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    const { open } = props
    const { pathname } = useLocation()

    React.useEffect(
        function (): undefined | (() => void) {
            if (!open) {
                return undefined
            }
            const handler = (): void => dispatch({ type: 'resize', height: window.innerHeight })
            handler()
            window.addEventListener('resize', handler)
            return (): void => window.removeEventListener('resize', handler)
        },
        [open],
    )

    React.useEffect(
        function () {
            dispatch({ type: 'zoomout' })
        },
        [pathname],
    )
}

export const Gallery = addState(GalleryUI, reducer, initial, useEffect)
