import * as React from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'
import { Redirect, useHistory } from 'react-router'
import { Location } from 'history'
import FocusTrap from 'focus-trap-react'

import { useLocalisation } from '../../lib/i18n'
import { useBlockScroll } from '../../lib/use-block-scroll'
import { ReleaseAllImagesQuery } from '../../api/types'
import { ChevronLeft, ChevronRight, SearchPlus, SearchMinus, Chain, Edit, Close } from '../../lib/components/icon'
import { Button, AnchorButton } from '../../lib/components/buttons'
import { Spinner } from '../../lib/components/spinner'
import { Link } from '../../lib/components/link'
import { Picture, PictureSet } from '../../lib/components/picture'
import { NSWFImagePlaceholderLarge, NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'

import { WithProps } from '../../lib/add-state'
import { useKeyPress } from '../../lib/use-key-press'
import { esc, left, right } from '../../lib/keycodes'
import { Props, State, Action } from '.'
import { useLogin } from '../../lib/login'
import { explicitMasterContent, explicitReleaseContent } from '../../lib/explicit-contentIds'

import css from './styles.css'
import { useMediaQuery } from '@react-hook/media-query'

type ImageEdge = NonNullable<ReleaseAllImagesQuery['release']>['images']['edges'][number]
type Images = NonNullable<Props['images']>
type UIProps = WithProps<Props, State, Action>

export function GalleryUI(props: UIProps): React.ReactElement | null {
    const { i18n } = useLocalisation()
    const history = useHistory()
    const { loggedIn } = useLogin()

    const {
        open,
        imageId,
        images,
        dispatch,
        zoomedIn,
        permalink,
        editUrl,
        windowHeight,
        error,
        loading,
        makeUrl,
        name,
        discogsId,
    } = props

    const index = images?.edges.findIndex((image: ImageEdge): boolean => image.node?.id === imageId) ?? -1
    const len = images?.edges.length ?? 0
    const currImage = (index >= 0 && index < len && images?.edges[index].node) || undefined
    const prevImage = images?.edges[(len + index - 1) % len]?.node
    const nextImage = images?.edges[(len + index + 1) % len]?.node

    const blockForLoggedInUsers =
        explicitReleaseContent.includes(discogsId ?? 0) || explicitMasterContent.includes(discogsId ?? 0)

    function onClose(): void {
        history.replace(makeUrl(null))
    }

    function next(): void {
        if (nextImage) {
            history.replace(makeUrl(nextImage.id))
        }
    }

    function prev(): void {
        if (prevImage) {
            history.replace(makeUrl(prevImage.id))
        }
    }

    useKeyPress(
        {
            [esc]: onClose,
            [left]: prev,
            [right]: next,
        },
        !open,
    )

    useBlockScroll({ block: open })

    if (!open || !imageId || error) {
        return null
    }

    function handleBackgroundClick(evt: React.MouseEvent<HTMLDivElement>): void {
        if ((evt.target as HTMLElement).tagName === 'IMG' || (evt.target as HTMLElement).id !== 'image-gallery') {
            return
        }

        onClose()
    }

    // 235px = 6em + 150px
    const canZoom = currImage && windowHeight - 235 < currImage.fullsize.height
    function toggleZoom(): void {
        if (canZoom && !zoomedIn) {
            dispatch({ type: 'zoomin' })
        } else {
            dispatch({ type: 'zoomout' })
        }
    }

    if (!loading && !currImage) {
        return <Redirect to={makeUrl(null)} />
    }

    function mainGalleryImage(): React.ReactElement | null {
        if (currImage) {
            if ((currImage.nsfw && !loggedIn) || blockForLoggedInUsers) {
                return <NSWFImagePlaceholderLarge />
            }
            return (
                <div className={css.wrap}>
                    <button className={css.toggleZoom} aria-label={t(i18n)`Toggle Zoom`} onClick={toggleZoom}>
                        <Picture
                            image={currImage.fullsize}
                            alt={
                                index === 0
                                    ? t(i18n)`${name}, Primary, ${index + 1} of ${images?.totalCount}`
                                    : t(i18n)`${name}, Secondary, ${index + 1} of ${images?.totalCount}`
                            }
                            className={css.large}
                            width={600}
                            height={600}
                        />
                    </button>
                </div>
            )
        }
        return null
    }

    const classname = classnames(css.overlay, zoomedIn && css.zoomin, canZoom && css.canzoom)
    return (
        <FocusTrap>
            <div className={classname}>
                <h1 className={css.srOnly}>
                    <Trans>Image Gallery</Trans>
                </h1>
                <div className={css.nav}>
                    <Permalink open={permalink} imageId={currImage?.id} dispatch={dispatch} makeUrl={makeUrl} />
                    <Button className={css.zoom} onClick={toggleZoom} disabled={!canZoom}>
                        {zoomedIn ? (
                            <>
                                <SearchMinus aria-hidden='true' /> <Trans>Zoom Out</Trans>
                            </>
                        ) : (
                            <>
                                <SearchPlus aria-hidden='true' /> <Trans>Zoom In</Trans>
                            </>
                        )}
                    </Button>
                    {editUrl && (
                        <AnchorButton className={css.edit} href={editUrl}>
                            <Edit aria-hidden='true' /> <Trans>Edit Images</Trans>
                        </AnchorButton>
                    )}
                    <button title='Close' onClick={onClose} className={css.close}>
                        <Close aria-hidden='true' />
                    </button>
                    <button onClick={prev} className={css.arrow} aria-label={t(i18n)`Previous image`}>
                        <ChevronLeft aria-hidden='true' />
                    </button>
                    <button onClick={next} className={css.arrow} aria-label={t(i18n)`Next image`}>
                        <ChevronRight aria-hidden='true' />
                    </button>
                </div>
                {/* eslint-disable jsx-a11y/no-static-element-interactions  */}
                <div className={css.container} onClick={handleBackgroundClick} id='image-gallery'>
                    {loading && <Spinner className={css.loading} />}
                    {mainGalleryImage()}
                </div>
                {images && (
                    <Thumbnails
                        images={images}
                        index={index}
                        makeUrl={makeUrl}
                        name={name}
                        blockForLoggedInUsers={blockForLoggedInUsers}
                    />
                )}
            </div>
        </FocusTrap>
    )
}

type PlaceholdersProps = {
    totalCount: number
    length: number
}

function Placeholders(props: PlaceholdersProps): React.ReactElement {
    const { totalCount, length } = props

    const placeholders = new Array(Math.max(0, totalCount - length))
        .fill(null)
        .map(function (_: null, i: number): React.ReactElement {
            /* eslint-disable react/no-array-index-key */
            return (
                <li key={i} className={css.placeholder}>
                    <button type='button' disabled>
                        <Spinner className={css.spinner} delay={1000} />
                    </button>
                </li>
            )
        })

    return <>{placeholders}</>
}

type ThumbnailsProps = {
    images: Images
    index: number
    name?: string
    makeUrl: (imageId: string | null) => Location
    blockForLoggedInUsers: boolean
}

function Thumbnails(props: ThumbnailsProps): React.ReactElement {
    const { loggedIn } = useLogin()

    const { images, index, makeUrl, name, blockForLoggedInUsers } = props
    const mobileBreakpoint = `(max-width: 600px)`

    const mobile = useMediaQuery(mobileBreakpoint)

    const ref = React.useRef<HTMLUListElement | null>(null)
    useScrollIntoView(ref, index)
    const { i18n } = useLocalisation()

    const thumbnails = images.edges.map(function (edge: ImageEdge, idx: number): React.ReactElement | null {
        if (!edge.node) {
            return null
        }

        const active = index === idx
        const classname = classnames(css.thumbnail, active && css.active)
        const href = makeUrl(edge.node.id)

        const { thumbnail, fullsize } = edge.node
        const ratio = fullsize.width / fullsize.height
        const h = Math.round((1000 * 100) / ratio) / 1000
        const pad = Math.round((1000 * 2) / ratio) / 1000
        const height = `calc(${h}vw - ${pad}em)`
        if ((edge.node.nsfw && !loggedIn) || blockForLoggedInUsers) {
            const nsfwClassname = classnames(css.thumbnail, active && css.active, css.nsfwInlineImage)
            return (
                <li key={edge.node.id} className={nsfwClassname} style={{ height }}>
                    <Link internal replace href={href}>
                        {mobile ? <NSWFImagePlaceholderLarge /> : <NSFWImagePlaceholderSmall showButton={false} />}
                    </Link>
                </li>
            )
        }

        return (
            <li key={edge.node.id} className={classname} style={{ height }}>
                <Link internal replace href={href}>
                    <PictureSet
                        images={[fullsize, thumbnail]}
                        lazy={!active}
                        sizes='(max-width: 600px) 94vw, 150px'
                        alt={
                            idx === 0
                                ? t(i18n)`${name}, Primary, ${idx + 1} of ${images.edges.length}`
                                : t(i18n)`${name}, Secondary, ${idx + 1} of ${images.edges.length}`
                        }
                    />
                </Link>
            </li>
        )
    })

    return (
        /* eslint-disable jsx-a11y/no-redundant-roles */
        <ul className={css.thumbnails} ref={ref} role='list'>
            {thumbnails}
            <Placeholders totalCount={images.totalCount} length={images.edges.length} />
        </ul>
    )
}

function useScrollIntoView(ref: React.RefObject<HTMLUListElement | null>, index: number): void {
    React.useEffect(
        function () {
            if (!ref.current || index < 0) {
                return
            }

            const { scrollLeft, scrollTop } = ref.current
            const base = ref.current.getBoundingClientRect()
            const el = ref.current.getElementsByTagName('li')[index].getBoundingClientRect()
            const margin = 10

            if (window.innerWidth < 600) {
                if (el.top < 0 || el.bottom > window.innerHeight) {
                    ref.current.scrollTo({
                        top: scrollTop + el.top - margin,
                        behavior: 'smooth',
                    })
                }

                return
            }

            if (base.left > el.left) {
                ref.current.scrollTo({
                    left: scrollLeft + el.left - base.left - margin,
                    behavior: 'smooth',
                })
                return
            }

            if (base.right < el.right) {
                ref.current.scrollTo({
                    left: scrollLeft + el.right - base.right + margin,
                    behavior: 'smooth',
                })
            }
        },
        [index],
    )
}

type PermalinkProps = {
    imageId?: string
    open: boolean
    dispatch: UIProps['dispatch']
    makeUrl: (imageId: string | null, isPerma?: boolean) => Location
}

function Permalink(props: PermalinkProps): React.ReactElement {
    const { open, dispatch, imageId, makeUrl } = props

    const ref = React.useRef<HTMLInputElement | null>(null)

    const url = makeUrl(imageId ?? null, true)
    const link = `https://www.discogs.com${url.pathname}${url.search}${url.hash}`
    const width = `${link.length / 1.6}em`

    React.useEffect(
        function (): () => void {
            if (!open || !ref.current) {
                return (): void => undefined
            }

            ref.current.focus()
            ref.current.setSelectionRange(0, link.length)

            function handler(): void {
                dispatch({ type: 'close-permalink' })
            }

            ref.current.addEventListener('blur', handler)
            return (): void => ref.current?.removeEventListener('blur', handler)
        },
        [open, link],
    )

    if (!open || !imageId) {
        return (
            <Button className={css.permabutton} onClick={(): void => dispatch({ type: 'permalink' })}>
                <Chain aria-hidden='true' /> <Trans>Permalink</Trans>
            </Button>
        )
    }

    return <input value={link} className={css.permalink} ref={ref} style={{ width }} readOnly />
}
